body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	/* font-size: small !important; */
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

.x-small {
	font-size:  x-small !important;
}

.xx-small {
	font-size:  xx-small !important;
}

/* Page loading start */
.loader {
	/* margin: 0.5em 0 0 0.1em; */
	border: 14px solid #f3f3f3; /* Light grey */
	border-top: 14px solid #3498db; /* Blue */
	border-radius: 50%;
	width: 100px;
	height: 100px;
	animation: spin 2s linear infinite;
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

/* Page loading end */

/* component loading start */
.progress {
	background: linear-gradient(#0f6efd 0 0) left/0% 100% no-repeat #dbdcef;
}

@keyframes progress-422c3u {
	100% {
		background-size: 120% 100%;
	}
}

/* component loading end */

/* timer loader start */
.spinner {
	position: relative;
	width: 24px;
	height: 24px;
}

.spinner::before,
.spinner::after {
	content: '';
	width: 100%;
	height: 100%;
	animation: spinner-rfi6tk 1.2s infinite linear;
	box-sizing: border-box;
	border: 4.8px solid rgba(15, 110, 253, 0.1);
	border-radius: 50%;
	position: absolute;
}

.spinner::before {
	animation-direction: reverse;
	border-right-color: #0f6efd;
	right: calc(50% - 2.4px);
}

.spinner::after {
	border-left-color: #0f6efd;
	left: calc(50% - 2.4px);
}

@keyframes spinner-rfi6tk {
	0% {
		transform: rotate(0deg);
	}

	50%,
	100% {
		transform: rotate(360deg);
	}
}
/* timer loader end */

/* React table start*/
.accordion-header,
.accordion-item {
	border-radius: 0 !important;
	border: none !important;
	padding: 0 !important;
}
.accordion-button {
	color: #000 !important;
	background-color: #f9fafb !important;
	border-color: #f9fafb !important;
	padding: 0.25rem 0.5rem !important;
	font-size: 0.875rem !important;
	border-radius: 0.25rem !important;
	width: auto !important;
}
.accordion-button::after {
	margin-top: 2px !important;
    content: "▼" !important;
    background-image: none !important;	
}
.accordion-button:not(.collapsed)::after {
	content: "▲" !important;
	transform: rotate(0)!important;
	background-image: none !important;
}
.accordion:focus,
.accordion-button:focus {
	box-shadow: none !important;
}
.accordion-button:not(.collapsed) {
	color: #212529;
}
.accordion-body {
	padding: 1em !important;
}

/* React table end*/

.no-focus:focus {
	box-shadow: none !important;
}

/* Auto hide static notification start */
.hide-in-3s {
    -moz-animation: cssAnimation 0s ease-in 3s forwards;
    /* Firefox */
    -webkit-animation: cssAnimation 0s ease-in 3s forwards;
    /* Safari and Chrome */
    -o-animation: cssAnimation 0s ease-in 3s forwards;
    /* Opera */
    animation: cssAnimation 0s ease-in 3s forwards;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
}
@keyframes cssAnimation {
    to {
        width:0;
        height:0;
        overflow:hidden;
    }
}
@-webkit-keyframes cssAnimation {
    to {
        width:0;
        height:0;
        visibility:hidden;
    }
}
/* Auto hide static notification end */

/* analytics map start */
.invisible-marker {
    width: 10px; /* Minimal width for interaction */
    height: 10px; /* Minimal height for interaction */
    margin-left: -5px; /* Centers the clickable area */
    margin-top: -5px;
    cursor: pointer; /* Indicates it's clickable */
}
/* analytics map end */