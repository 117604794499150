.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-inner {
  position: relative;
  padding: 2rem;
  width: 100%;
  max-width: 650px;
  /* background-color: #fff; */
}

.popup-inner .close-btn {
  position: absolute;
  top: 1em;
  right: 1em;
}
